@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "IBM Plex Sans", sans-serif;
  }
}

body {
  padding: 0;
  margin: 0;
  background-color: white;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb,
.member--info::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c8baba;
  margin: 0 1px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.phone > input {
  outline: none;
}

.nav {
  position: fixed;
}

.custom-select .ant-select-arrow {
  @apply absolute right-5 ;
}


@media (max-width: 600px) {
  .brand-logo {
    width: 25px;
    height: auto;
  }
}
