.login-container {
  background: #ffffff;
  border: 0.5px solid #eceeee;
  box-shadow: 0px 13px 64px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.countrycode {
  width: 100%;
  padding: 0 !important;
  height: 30px;
  
}
